<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <input
            class="block w-full px-4 py-2 mb-3 border border-gray-400 rounded-full shadow-sm appearance-none focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :class="{
                'bg-gray-200 text-gray-500': (disabled || readonly),
                'bg-white text-gray-700': (!disabled && !readonly),
            }"
            :id="id"
            :name="name"
            :readonly="readonly"
            :disabled="disabled"
            :type="type"
            :step="step"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            :value="computedValue"
            @input="updateValue($event.target.value)"
            @keyup.enter="$emit('keyupenter', $event.target.value)"
        >
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-input',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            name: String,
            label: String,
            required: Boolean,
            readonly: Boolean,
            disabled: Boolean,
            placeholder: String,
            step: String,
            min: String,
            max: String,
            type: {
                type: String,
                default: 'text',
            },
            modelValue: {
                type: [String, Number, Function],
            },
        },
        emits: [
            'update:modelValue',
            'keyupenter',
        ],
        computed: {
            computedValue() {
                if (typeof this.modelValue === 'function') {
                    return this.modelValue();
                } else {
                    return this.modelValue;
                }
            },
        },
        methods: {
            updateValue(newValue) {
                this.$emit('update:modelValue', newValue);
            }
        }
    }
</script>
