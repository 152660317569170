<template>
    <div>
        <form-label :id="id" :label="label"></form-label>
        <textarea
            class="block w-full px-4 py-2 mb-3 text-gray-700 bg-white border border-gray-400 rounded-lg shadow-sm appearance-none focus:outline-none focus:bg-white focus:border-sbr-purple focus:ring-sbr-purple"
            :id="id"
            :name="name"
            rows="5"
            :readonly="readonly"
            v-model="input_value">
        </textarea>
    </div>
</template>

<script>
    import FormLabel from './Label'

    export default {
        name: 'form-textarea',
        components: {
            FormLabel,
        },
        props: {
            id: String,
            name: String,
            label: String,
            required: Boolean,
            readonly: Boolean,
            modelValue: {
                type: [String, Number],
            },
        },
        emits: [
            'update:modelValue',
        ],
        computed: {
            input_value: {
                get() {
                    return this.modelValue
                },
                set(val) {
                    this.$emit('update:modelValue', val)
                }
            },
        },
    }
</script>
